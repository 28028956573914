import { AuthenticationResult, EndSessionPopupRequest, InteractionRequiredAuthError, LogLevel, PopupRequest, PublicClientApplication, RedirectRequest, ServerError, Configuration } from '@azure/msal-browser';
import { UrlParameterKey } from 'constants/urlParameters';
import { ConfigEnv } from './configEnv';

const authScopes = ['https://skyllfulplatform.onmicrosoft.com/vcb-api/Access'];

const getMsalConfig = (): Configuration => {
  return {
    auth: {
      clientId: ConfigEnv.REACT_APP_CLIENT_ID || '', // This is the ONLY mandatory field; everything else is optional.
      authority: ConfigEnv.REACT_APP_AUTH_URL, // Choose sign-up/sign-in user-flow as your default.
      knownAuthorities: [ConfigEnv.REACT_APP_AUTH_DOMAIN || ''], // You must identify your tenant's domain as a known authority.
    },
    cache: {
      cacheLocation: 'localStorage'
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      },
      windowHashTimeout: 300000
    }
  };
};

export let msalConfig: Configuration = getMsalConfig();

export let msalInstance = new PublicClientApplication(msalConfig);

const setMsalInstance = (): void => {
  msalInstance = new PublicClientApplication(msalConfig);
};

export const refreshAuthConfig = () => {
  msalConfig = getMsalConfig();
  setMsalInstance();
};

export const getUserDomain = () => {
  const userName = msalInstance.getActiveAccount()?.username;
  if (!userName) {
    return undefined;
  }

  if (userName.indexOf('@') === -1) {
    return undefined;
  }

  var split = userName.split('@');
  return split.length === 2 ? split[1] : '';
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const silentloginRequest: PopupRequest = {
    scopes: authScopes
};

export const getSsoRequest = (): PopupRequest => ({
  scopes: authScopes,
  redirectUri: `${ConfigEnv.REACT_APP_PUBLIC_URL}/blank.html`
});

export const getLoginRedirectRequest = () : PopupRequest => ({
  scopes: authScopes,
  domainHint: getUserDomain(),
  redirectUri: ConfigEnv.REACT_APP_PUBLIC_URL
});

export const popupRequest: PopupRequest = {
  scopes: authScopes,
  prompt: 'select_account'
};

export const signIn = async () => {
  clearCookies();  
  clearTokens();
  
  await msalInstance.loginPopup(popupRequest);    
};

export const callRedirectPromiseSso = async () => {
  callRedirectPromise(true); 
}

export const callRedirectPromise = async (isSso: boolean = false) => {
  clearCookies();  
  clearTokens();

  const loginRequest = createLoginRedirectRequest();

  if (isSso) {
    loginRequest.domainHint = ConfigEnv.REACT_APP_ENABLE_AUTO_SSO_DOMAIN_HINT;
  }

  msalInstance.handleRedirectPromise().then((redirectTokenResult) => {
    if (redirectTokenResult !== null) {
       msalInstance.setActiveAccount(redirectTokenResult.account);
       localStorage.setItem(idToken, redirectTokenResult.accessToken);
    } else {
       loginRedirect(loginRequest);
     }
  });
}

export const logout = () => {
  clearTokens();
  clearCookies();  

  const logoutRequest: EndSessionPopupRequest = {
    mainWindowRedirectUri: ConfigEnv.REACT_APP_PUBLIC_URL
  };

  const currentAccount = msalInstance.getActiveAccount();
  // Logged using AAD Provider
  if (currentAccount?.idTokenClaims?.idp && currentAccount?.idTokenClaims?.idp?.indexOf('https://login.microsoftonline.com') !== -1) {
    logoutRequest.postLogoutRedirectUri = `https://login.microsoftonline.com/${currentAccount.tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${ConfigEnv.REACT_APP_PUBLIC_URL}&domain_hint=skyllful.com`
  }

  msalInstance.logoutPopup(logoutRequest);
};

const idToken = 'skyllful.idtoken';
const homeAccountId = 'skyllful.homeAccountId';
const iqToken = 'skyllful.iq.idtoken';
const simulatorToken = 'skyllful.simulator.idtoken';

/*
  This logic is to create a singleton for the refresh token call, so if we have multiple request going at the same time we don`t overload the Azure B2C with several calls for new tokens
*/
let refreshTokenPromise: Promise<string> | null;
export const refreshIdToken = async () => {

  if (ConfigEnv.REACT_APP_USE_TWO_STEPS_AUTH_FLOW) {
    const authResult = await getTokenUsingTwoStepsFlow();
    if (authResult) {
      setStorageVariables(authResult);
    }

    return authResult?.accessToken ?? null;
  }

  if (!refreshTokenPromise) {
    refreshTokenPromise = new Promise<string>(async (resolve, reject) => {
      try {
        msalInstance.setActiveAccount(msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0]);
        
        const silentResult = await msalInstance.acquireTokenSilent(silentloginRequest);
        setStorageVariables(silentResult);
        resolve(silentResult.accessToken);
      } catch (silentError) {
        console.log(silentError);
        try {
          var ssoResult = await msalInstance.ssoSilent(getSsoRequest());
          msalInstance.setActiveAccount(ssoResult.account!);
          setStorageVariables(ssoResult);
          resolve(ssoResult.accessToken);
        } catch (ssoError) {
          console.log(ssoError);
    
          try {
            var redirectTokenResult = await msalInstance.handleRedirectPromise();
          
            if (redirectTokenResult !== null) {
              msalInstance.setActiveAccount(redirectTokenResult.account!);
              setStorageVariables(redirectTokenResult);
              resolve(redirectTokenResult.accessToken);
            } else {
              await loginRedirect(getLoginRedirectRequest());
            }
          } catch (loginRedirectError) {
            reject(loginRedirectError);
          }
        }
      }

      refreshTokenPromise = null;
    });
  }
  
  return refreshTokenPromise;
};

export const getIdToken = () : string => localStorage.getItem(idToken) || '';

export const logoutSilently = (): void => {
  clearCookies();
  msalInstance.logoutRedirect({
    account: msalInstance.getActiveAccount(),
    onRedirectNavigate: (_url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
    }
  });
};

export function clearCookies() {
  document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
}

export function clearTokens() {
  localStorage.removeItem(idToken);
  localStorage.removeItem(homeAccountId);
  localStorage.removeItem(iqToken);
  localStorage.removeItem(simulatorToken);
}

export function extractErrorMessage(error: ServerError) : string | null {
  return null;
  // TODO: Paulo - Makes it work on safari - https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
  // var errorMessage = error.errorMessage.match(/(?<=AADB2C:)(.*)(?=Correlation )/s);  

  // if (errorMessage && errorMessage?.length > 0) {
  //   return errorMessage[0];
  // } else {
  //   return null;
  // }
}

function setStorageVariables(authenticationResult: AuthenticationResult) {
  localStorage.setItem(idToken, authenticationResult.accessToken);
  localStorage.setItem(homeAccountId, authenticationResult.account?.homeAccountId || '');
}

async function loginRedirect(request?: RedirectRequest | undefined): Promise<void> {
  const msalInteractionStatusKey = 'msal.interaction.status';
  localStorage.removeItem(msalInteractionStatusKey)
  sessionStorage.removeItem(msalInteractionStatusKey);
  await msalInstance.loginRedirect(request);
}

function createLoginRedirectRequest(): RedirectRequest {
  if (ConfigEnv.REACT_APP_ENABLE_AUTO_ENROLLMENT) {

    const url = new URLSearchParams(window.location.search);
    url.delete(UrlParameterKey.Error);

    const redirectStartPage = window.location.search ? 
    window.location.href.replace(window.location.search, '?' + url.toString()) : 
    window.location.href;

    const loginRequest: RedirectRequest = {
      scopes: authScopes,
      prompt: 'select_account',
      redirectUri: ConfigEnv.REACT_APP_PUBLIC_URL,
      redirectStartPage: redirectStartPage
    };

    return loginRequest;
  } else {
    // Keep the previous behaviour
    const url = new URLSearchParams(window.location.search);
    url.delete(UrlParameterKey.Error);

    const loginRequest: RedirectRequest = {
      scopes: authScopes,
      prompt: 'select_account',
      redirectStartPage: `${ConfigEnv.REACT_APP_PUBLIC_URL}?${url.toString()}`
    };

    return loginRequest;
  }
}

export const removeAccountsFromDifferentAuthority = async () => {
  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts.some((a) => a.environment !== ConfigEnv.REACT_APP_AUTH_DOMAIN)) {
    await msalInstance.clearCache();
  }
};

const handleLoginRedirect = () => new Promise<AuthenticationResult>((resolve) =>  {
  msalInstance.handleRedirectPromise().then((redirectTokenResult) => {
    if (redirectTokenResult !== null) {
      msalInstance.setActiveAccount(redirectTokenResult.account!);
      setStorageVariables(redirectTokenResult);
      resolve(redirectTokenResult);
    } else {
      loginRedirect(getLoginRedirectRequest());
    }
  });            
});

const getTokenUsingTwoStepsFlowInternal = async () => {

  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts.length === 0) {
    return;
  }

  if (!msalInstance.getActiveAccount()) {
    return await handleLoginRedirect();
  }

  return await msalInstance.acquireTokenSilent({
    redirectUri: `${ConfigEnv.REACT_APP_PUBLIC_URL}/blank.html`,
    scopes: authScopes,
  }).catch((error) => {
    console.error(error);
    return handleLoginRedirect();
  });
};

let getTokenUsingTwoStepsFlowPromise: Promise<AuthenticationResult | undefined> | null;

const createGetTokenUsingTwoStepsFlowPromise = () => {
  getTokenUsingTwoStepsFlowPromise = getTokenUsingTwoStepsFlowInternal().finally(() => getTokenUsingTwoStepsFlowPromise = null);

  return getTokenUsingTwoStepsFlowPromise;
};

export const getTokenUsingTwoStepsFlow = (): Promise<AuthenticationResult | undefined> =>
  getTokenUsingTwoStepsFlowPromise ?? createGetTokenUsingTwoStepsFlowPromise();
