import { ConfigEnvType } from './configEnvType';

declare global {
    interface Crypto {
    randomUUID: () => string;
  }
   interface Window { env: any; }
 }

const booleanValue = (value: any): boolean => value === true || value === 'true';

const defineEnv = (envKey: string): string => {
  return window.env && window.env[envKey] || process.env[envKey];
 };

export const ConfigEnv: ConfigEnvType = {
  REACT_APP_ENV: defineEnv('NODE_ENV'),
  REACT_APP_AUTH_DOMAIN: defineEnv('REACT_APP_AUTH_DOMAIN'),
  REACT_APP_AUTH_URL: defineEnv('REACT_APP_AUTH_URL'),
  REACT_APP_BASE_URL: defineEnv('REACT_APP_BASE_URL'),
  REACT_APP_CLIENT_ID: defineEnv('REACT_APP_CLIENT_ID'),
  REACT_APP_PUBLIC_URL: defineEnv('REACT_APP_PUBLIC_URL'),
  REACT_APP_SIMULATOR_URL: defineEnv('REACT_APP_SIMULATOR_URL'),
  REACT_APP_IQ_URL: defineEnv('REACT_APP_IQ_URL'),
  REACT_APP_STUDIO_V2_URL: defineEnv('REACT_APP_STUDIO_V2_URL'),
  REACT_APP_ENABLE_AUTO_SSO_LOGIN: defineEnv('REACT_APP_ENABLE_AUTO_SSO_LOGIN') === 'true',
  REACT_APP_ENABLE_AUTO_ENROLLMENT: defineEnv('REACT_APP_ENABLE_AUTO_ENROLLMENT') === 'true',
  REACT_APP_ENABLE_AUTO_SSO_DOMAIN_HINT: defineEnv('REACT_APP_ENABLE_AUTO_SSO_DOMAIN_HINT'),
  REACT_APP_IS_MULTI_TENANT: booleanValue(defineEnv('REACT_APP_IS_MULTI_TENANT')),
  REACT_APP_USE_TWO_STEPS_AUTH_FLOW: booleanValue(defineEnv('REACT_APP_USE_TWO_STEPS_AUTH_FLOW')),
};
