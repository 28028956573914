import { initSkyllfulClientSdk } from './initSkyllfulClientSdk';
import { ConfigEnv } from './configEnv';
import { ClientAppType, Configuration, ConfigurationsApi } from 'skyllful-client-sdk';
import { ConfigEnvType, ConfigKeys } from './configEnvType';
import { msalInstance, refreshAuthConfig, refreshIdToken, removeAccountsFromDifferentAuthority } from './authConfig';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

export const initMultiTenantConfig = async () => {
  initSkyllfulClientSdk();
  // window.origin is used so API requests go through frontdoor and get the multitenant headers added
  const basePath = window.origin.includes('localhost') ? ConfigEnv.REACT_APP_BASE_URL : window.origin;

  const api = new ConfigurationsApi(new Configuration({basePath}));
  const configurations = await api.apiConfigurationsGet(ClientAppType.Login);

  const mapping: Partial<ConfigEnvType> = {
    REACT_APP_AUTH_DOMAIN: configurations.reactAppAuthDomain,
    REACT_APP_AUTH_URL: configurations.reactAppAuthUrl,
    REACT_APP_BASE_URL: configurations.reactAppBaseUrl,
    REACT_APP_IQ_URL: configurations.reactAppIqUrl,
    REACT_APP_PUBLIC_URL: configurations.reactAppPublicUrl,
    REACT_APP_SIMULATOR_URL: configurations.reactAppSimulatorUrl,
    REACT_APP_STUDIO_V2_URL: configurations.reactAppStudioV2Url,
  };

  const assignConfigValue = <K extends keyof ConfigEnvType>(
    config: ConfigEnvType,
    key: K,
    value: ConfigEnvType[K]
  ) => {
    config[key] = value;
  };

  // Iterate through mapping and set properties on ConfigEnv for each prop
  Object.keys(mapping).forEach((key) => {
    const typedKey = key as ConfigKeys;
    const value: Partial<ConfigEnvType>[typeof typedKey] = mapping[typedKey];

    if (value !== undefined && value !== null) {
      assignConfigValue(ConfigEnv, typedKey, value);
    }
  });

  refreshAuthConfig();

  // When migrating customers from skyllfulplatform.b2clogin.com to {custom domain}.skyllful.app
  await removeAccountsFromDifferentAuthority();

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      refreshIdToken();
    }
  });
};